<template>
  <div class="components-product-system ">
    <div class="head-title  animate__animated animate__fadeInUp CallOut-title">
      <div class="line-content">
        <span class="line-item-left"></span>
        <h2 class="line-call">系统功能</h2>
        <span class="line-item"></span>
      </div>
      <p class="line-title">PRODUCT FUNCTION</p>
    </div>
    <!-- 第一种 -->

    <el-row style="padding-top: 64px;">
      <el-col :xs="24" :sm="24" :md="24">
        <el-col
          :xs="24"
          :sm="24"
          :md="7"
          class="thumbnailContainer  hidden-sm-and-down "
        >
          <div
            :class="[
              'thumbnailItem',
              thumbnailActive === index ? 'thumbnailItemActive' : '',
            ]"
            v-for="(item, index) in thumbnailList"
            :key="index"
            @click="thumbnailItemClick(index)"
          >
            <span>{{ item.name }}</span>
          </div>
        </el-col>
        <!--大屏上显示的主轮播图-->
        <el-col :xs="24" :sm="24" :md="17">
          <el-carousel
            style="width:100%"
            ref="carousel"
            arrow="never"
            :height="imgHeight + 'px'"
            :autoplay="false"
            indicator-position="none"
            @change="carouselChange"
            class="hidden-sm-and-down"
          >
            <el-carousel-item
              v-for="(item, index) in SystemList"
              :key="index"
              name="index"
            >
              <div class="contentText">
                <img
                  ref="bannerImg"
                  :src="item.imgList"
                  @load="initImgHeight"
                />

                <!-- <img v-lazy="item.imgList" @load="initImgHeight" /> -->
                <div class="content">
                  <div class="link-list">
                    <div class="title">{{ item.designation }}</div>
                    <p>{{ item.content }}</p>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </el-col>
      </el-col>

      <!-- 移动端 -->

      <el-col
        class="hidden-md-and-up thumbnail-content"
        :xs="24"
        :sm="24"
        :md="24"
      >
        <!-- 点击事件绑定在组件上要想有效果得加修饰符 native el-col-->
        <el-col
          :xs="8"
          :sm="8"
          class="thumbnail-item"
          :class="[
            'thumbnailItem',
            thumbnailActiveMobile === index ? 'thumbnailItemActive' : '',
          ]"
          v-for="(item, index) in thumbnailList"
          :key="index"
          @click.native="thumbnailItemClickMobile(index)"
        >
          <div>{{ item.name }}</div>
        </el-col>
      </el-col>

      <!-- 移动端主图 -->
      <el-col :xs="24" :sm="24" :md="24" class="hidden-md-and-up system-mobile">
        <el-carousel
          style="width:100%"
          ref="carouselMobile"
          arrow="never"
          :height="imgHeightMobile + 'px'"
          :autoplay="false"
          indicator-position="none"
          @change="carouselChangeMobile"
        >
          <el-carousel-item
            v-for="(item, index) in SystemList"
            :key="index"
            name="index"
          >
            <div class="contentText-mobile">
              <img
                ref="bannerImgMobile"
                :src="item.imgList"
                @load="initImgHeightMobile"
              />
              <div class="content-mobile">
                <div class="link-list">
                  <div class="title">{{ item.designation }}</div>
                  <p>{{ item.content }}</p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import WOW from "wowjs";
export default {
  name: "System",
  data() {
    return {
      active: false,
      imgHeight: 0,
      thumbnailActive: 0,
      imgHeightMobile: 0,
      thumbnailActiveMobile: 0,
      thumbnailList: [
        {
          name: "智能语音外呼",
        },
        {
          name: "语音模型调用",
        },
        {
          name: "智能知识库",
        },
        {
          name: "全量语音分析",
        },
        {
          name: "短信全用户触点",
        },
        {
          name: "呼入应答",
        },
      ],
      SystemList: [
        {
          imgList: require("@/assets/images/product/VoiceModel.png"),

          designation: "智能语音外呼",
          content:
            "机器人以批量外呼、多轮交互、智能助手、智能质检等多种形式配合人工坐席优化语音营销服务流程，缩短单通电话通话时长，提高坐席营销质量",
        },
         {
          imgList: require("@/assets/images/product/VoiceModel.png"),

          designation: "语音模型调用",
          content:
            "支持一键开启语音模型功能，自动采集坐席语料，用优秀客服流程主动进行模型训练。坐席可自由导入话术合成语音并快捷部署，随时开启人机协同服务模式",
        },
        {
          imgList: require("@/assets/images/product/VoiceModel.png"),

          designation: "智能知识库",
          content:
            "智能化管理企业海量非结构化的文档和数据，智能助手实时根据沟通需要调用专业话术和知识点，用于主动跟进任务或及时推送给人工坐席提高沟通反馈速度",
        },
        {
          imgList: require("@/assets/images/product/VoiceModel.png"),

          designation: "全量语音分析",
          content:
            "自动存储所有语音文件并分析建模，生成精细的客户、流程、坐席标签，为坐席任务匹配和推送销策略提供数据支持和画像支撑，充分挖掘语音信息的价值",
        },
       
        {
          imgList: require("@/assets/images/product/VoiceModel.png"),

          designation: "短信全用户触点",
          content:
            "系统内置海量创意短信模板，依据语音营销的结果自动差异化推送短信，完成语音营销后的结果确认或用户骚扰权益补偿，提升用户满意度和降低营销投诉率",
        },
        {
          imgList: require("@/assets/images/product/VoiceModel.png"),

          designation: "呼入应答",
          content:
            "及时响应部分客户呼叫未接回电及营销投诉呼入，为全量客户触达及降低营销投诉率提供保障",
        },
      ],
    };
  },
  mounted() {
    // this.pcShow();
    this.handlePageChange();
    this.handlePageChangeMobile();
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  create() {},

  beforeDestroy() {
    window.removeEventListener("resize", this.initImgHeight);
    window.removeEventListener("resize", this.initImgHeightMobile);
  },
  methods: {
    //初始化获取图片高度及文字容器，设置element轮播的高度
    initImgHeight() {
      // console.log(this.$refs.contentText[0].clientHeight);
      this.imgHeight = this.$refs.bannerImg[0].height;
    },
    //页面发生改变时重新获取图片高度及文字容器高度，并重新设置
    handlePageChange() {
      window.addEventListener("resize", this.initImgHeight);
    },
    //缩略图点击时切换
    thumbnailItemClick(index) {
      console.log("11");
      this.thumbnailActive = index;
      this.$refs.carousel.setActiveItem(index);
    },

    //轮播图切换时切换缩略图激活状态
    carouselChange(index) {
      this.thumbnailActive = index;
    },
    onYearClick(index) {
      this.histroyActive = index;
      this.$refs.carousel.setActiveItem(index);
    },
    initImgHeightMobile() {
      this.imgHeightMobile = this.$refs.bannerImgMobile[0].height;
      var mobileWidth = document.body.offsetWidth;

      if (881 < mobileWidth < 992) {
        this.imgHeightMobile = 600;
      }
      if (681 < mobileWidth < 760) {
        this.imgHeightMobile = 500;
      }
      if (mobileWidth < 680) {
        this.imgHeightMobile = 375;
      }
      if (mobileWidth < 375) {
        this.imgHeightMobile = 300;
      }

      // if (mobileWidth < 680) {
      //   this.imgHeightMobile = 375;
      //   // console.log(mobileWidth, this.imgHeightMobile);
      // }
    },

    handlePageChangeMobile() {
      window.addEventListener("resize", this.initImgHeightMobile);
    },

    //移动端缩略图点击时切换
    thumbnailItemClickMobile(index) {
      console.log("index");
      console.log("22");
      this.thumbnailActiveMobile = index;
      this.$refs.carouselMobile.setActiveItem(index);
    },

    //移动端轮播图切换时切换缩略图激活状态
    carouselChangeMobile(index) {
      console.log("index");
      this.thumbnailActiveMobile = index;
    },
  },
};
</script>
<style lang="less" scope>
.components-product-system {
  .CallOut-title {
    .line-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 63%;
      margin: 0 auto;
      .line-call {
        font-size: 0.5rem;
        font-family: SimHei;
        color: #333333;
        letter-spacing: 7px;
      }
      .line-item {
        background: url("../../assets/images/product/line-title-right.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
      .line-item-left {
        background: url("../../assets/images/product/line-title-left.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
    }
    .line-title {
      font-size: 0.3rem;
      font-family: SimHei;
      color: #666666;
      letter-spacing: 10px;
    }
  }
  width: 80%;
  margin: 0 auto;
  // pc
  .thumbnailContainer {
    padding-top: 24px;
    .thumbnailItem {
      // padding: 27px;
      height: 0.9875rem;
      line-height: 0.9875rem;
      /*height: 2.15rem;*/
      position: relative;
      cursor: pointer;
      span {
        display: inline-block;
        height: 100%;
        font-size: 0.325rem;
        font-family: SimHei;
        font-weight: 400;
        color: #999999;
        text-align: center;
        // line-height: 25px;
        letter-spacing: 2px;
      }
    }
    .thumbnailItem:not(.thumbnailItemActive)::after,
    .thumbnailItem:not(.thumbnailItemActive)::before {
      content: "";
      position: absolute;
      height: 1px;
      left: 0;
      right: 0;
      background: linear-gradient(to right, #fff, #e0e3f7, #fff);
    }
    .thumbnailItem:not(.thumbnailItemActive)::after {
      bottom: 0;
    }
    .thumbnailItem:not(.thumbnailItemActive)::before {
      top: 0;
    }
    .thumbnailItemActive::before {
      content: "";
      position: absolute;
      bottom: -21px;
      left: 0;
      right: 0;
      background: url("../../assets/images/product/back-img4.jpg") no-repeat;
      background-size: cover;
      width: 100%;
      height: 21px;
    }
    .thumbnailItemActive::after {
      content: ""; //伪元素必须要有这句话 且一般伪元素是行内元素，不能直接给高宽，
      position: absolute;
      top: -13px;
      left: 0;
      right: 0;
      background: url("../../assets/images/product/back-img5.jpg") no-repeat;
      background-size: cover;
      width: 100%;
      height: 13px;
    }
    .thumbnailItemActive {
      position: relative;

      // border: 1px solid #e0e3f7;
      span {
        // box-shadow: 0px 0px 24px 0px rgba(49, 88, 255, 0.26);
        font-size: 0.375rem;
        font-family: SimHei;
        font-weight: 400;
        color: #333333;
        z-index: 1;
        // line-height: 25px;
        &::after,
        &::before {
          content: ""; //伪元素必须要有这句话 且一般伪元素是行内元素，不能直接给高宽，我们可以转换一下，通过display："block",转换成块或行内块
          position: absolute; //或者用定位，定位的元素可以设置高宽
          bottom: 0;
          top: 0;
          width: 0.7125rem;
          background: url("../../assets/images/product/icon-67.png") no-repeat
            100% 100%;
          background-position: center;
          background-size: contain;
        }
      }
      span::after {
        left: 0.3125rem;
      }
      span::before {
        right: 0.3125rem;
      }
    }
  }
  .contentText {
    position: relative;
    .content {
      width: 61%;
      height: 45%;
      position: absolute;
      right: -7%;
      bottom: 2%;
      background: url("../../assets/images/product/back-img10.png");

      .link-list {
        width: 100%;
        position: absolute;
        right: -12%;
        bottom: 8%;
        text-align: left;
        z-index: 999;
        letter-spacing: 2px;
        // background: #fff;
        font-size: 0.2rem;
        font-family: SimHei;
        color: #999999;
        line-height: 0.4125rem;

        .title {
          padding-bottom: 0.3rem;
          font-size: 0.375rem;
          font-family: SimHei;
          color: #333333;
        }
        p {
          width: 79%;
        }
      }
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .content-wrapper {
    position: relative;

    .imgList {
      width: 100%;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  // 移动端

  .thumbnail-content {
    .thumbnail-item {
      font-size: 0.375rem;
      font-family: SimHei;
      font-weight: 400;
      color: #333333;
      letter-spacing: 1.5px;
      padding: 0.125rem 0;
      display: relative;

      div {
        padding: 15px 0;
        color: #666666;
      }
      &::after {
        content: "";
        position: absolute;
      }
    }
    .thumbnailItemActive {
      transform: scale(1, 1.1);
      background: linear-gradient(0deg, #ffa65f, #de7150);
      box-shadow: 0px 4px 29px 3px rgba(226, 79, 51, 0.35);
      border-radius: 5px;
      div {
        color: #fff;
      }
    }
  }
  .system-mobile {
    .contentText-mobile {
      img {
        max-width: 100%;
      }
      .content-mobile {
        .link-list {
          text-align: left;

          .title {
            font-size: 0.3rem;
            padding: 15px 0;
            color: #3387f1;
          }
          p {
            font-size: 0.3rem;
            letter-spacing: 1.5px;
            color: #999999;
          }
        }
      }
    }
  }
}

.el-carousel__container {
  height: 561px;
}
@media screen and (max-width: 1500px) {
  .components-product-system {
    .el-carousel__container {
      height: 500px;
    }
    .thumbnailContainer .thumbnailItemActive::after {
    }
  }
}
@media screen and (max-width: 1200px) {
  .components-product-system {
    .el-carousel__container {
      height: 400px;
    }

    .thumbnailContainer .thumbnailItemActive::after {
    }
  }
}
@media screen and (max-width: 992px) {
  .components-product-system {
    .system-thumb {
      padding-left: 1.25rem;
    }
    .system-Pc {
      height: 6.25rem;
    }
    .contentText .content {
      width: 100%;
      height: 34%;

      right: 0;
      bottom: -20%;
      .link-list {
        right: 0;
        bottom: 0;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .components-product-system {
    .thumbnail-content {
      .thumbnail-item {
        width: 50%;
      }
    }
  }
}
</style>
