<template>
  <div class="views-product-Banner">
    <el-row class="Banner-header ">
      <div class="small-img"></div>
      <el-col :span="24" class="Banner-left">
        <!-- <el-col :span="24" :offset="8"> -->

        <ul>
          <li v-for="(item, index) in dataList" :key="index">
            <div class="Banner-name">
              {{ item.name }}
              <img v-lazy="item.leftImg" alt="" />
            </div>
            <div class="Banner-title">
              <!-- 智能语音系统 -->
              <img v-lazy="item.rightImg" alt="" />
            </div>
            <!-- <div class="word">INTELLIGENT VOICE SYSTEM</div> -->
            <div class="line"></div>
            <div class="Banner-content">
              {{ item.content }}
            </div>
            <div class="Banner-button">
              <el-button class="Banner-buttonLight">{{
                item.buttonLeft
              }}</el-button>
              <el-button class="Banner-buttonRight">{{
                item.buttonRight
              }}</el-button>
            </div>
            <el-button class="Banner-buttonLast" @click="onDemoClick"
              >免费试用</el-button
            >
          </li>
        </ul>
        <!-- </el-col> -->
      </el-col>
      <!-- <el-col :xs="24" :sm="24" :md="24" class="hidden-md-and-up">
        <img v-lazy="build" class="Banner-image" />
      </el-col> -->
    </el-row>
  </div>
</template>
<script>
import WOW from "wowjs";
export default {
  name: "Banner",

  props: {},
  data() {
    return {
      build: require("@/assets/images/product/banner.png"),

      buildSmall: require("@/assets/images/product/banner.png"),
      dataList: [
        {
          name: "话加系列",
          leftImg: require("@/assets/images/product/icon-69.png"),
          rightImg: require("@/assets/images/product/icon-70.png"),
          content: "人机协呼赋能产能升级 智能数据分配提升坐席单产",
          buttonLeft: "实时监听",
          buttonRight: "无感介入",
        },
      ],
    };
  },
  create() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {
    onDemoClick() {
      this.$router.push({ name: "applyPage" });
    },
    onDemoClickMobile() {
      this.$router.push({ name: "applyPage" });
    },
  },
};
</script>
<style lang="less" scoped>
.views-product-Banner {
  position: relative;
  .Banner-header {
    position: relative;
    height: 536px;
    padding-top: 98px;
    padding-left: 304px;
    background: url("~@/assets/images/product/banner.png") no-repeat; //这张图片到移动端换图片了吗 没有
    background-size: cover;
    background-position: top center;
    z-index: -1;
    .small-img {
      position: absolute;
      background: url("../../assets/images/product/back-3.png") no-repeat;
      background-size: cover;
      left: 0;
      height: 342px;
      width: 300px;
      bottom: 0;
      z-index: 1;
    }
    .Banner-left {
      ul {
        text-align: left;
      }
      .Banner-name {
        font-size: 20px;
        font-family: HYZhengYuan;
        color: #ffffff;
        margin-bottom: 35px;
        img {
          width: 30px;
          height: 11px;
          text-align: center;
        }
      }
      .Banner-title {
        // font-size: 40px;
        // font-family: OPPOSans;
        // font-weight: 700;
        // color: #ffffff;
        // letter-spacing: 10px;
        img {
          height: 51px;
          width: 272px;
        }
      }
      .word {
        margin-top: 14px;
        font-size: 16px;
        font-family: SimHei;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 4px;
      }
      .line {
        width: 6.9375rem;
        height: 1px;
        // background: #5ebdf1;
        // opacity: 0.47;
        background: url("../../assets/images/product/line-banner.png");
        background-size: contain;
        margin-top: 18px;
      }
      .Banner-content {
        font-size: 16px;
        font-family: SimHei;
        color: #fff;
        margin: 16px 0 53px;
        letter-spacing: 2px;
      }

      .Banner-button {
        margin-bottom: 52px;

        .Banner-buttonLight {
          background: linear-gradient(90deg, #de7150, #ffa65f);
          box-shadow: 0px 4px 29px 3px rgba(226, 79, 51, 0.35);
          border-radius: 5px;
          border: none;
          color: #ffffff;
          padding: 8px 19px;
          font-size: 16px;
          letter-spacing: 2px;
        }
        .Banner-buttonRight {
          padding: 8px 19px;
          background: linear-gradient(90deg, #00b8f9, #69e4fd);
          box-shadow: 0px 4px 29px 3px rgba(10, 79, 142, 0.35);
          border-radius: 5px;
          font-size: 16px;
          border: none;
          color: #ffffff;
          text-align: center;
          letter-spacing: 2px;
        }
      }
      .Banner-buttonLast {
        padding: 8px 19px;
        font-size: 16px;
        text-align: center;
        letter-spacing: 2px;
        background: transparent;
        color: #ffffff;
      }

      // top: 50%;
    }
    .Banner-left-small {
      //   background: #5ebdf1;

      ul {
        text-align: left;
      }
      .Banner-name {
        margin-left: 10px;
        font-size: 0.25rem;
        font-family: HYZhengYuan;
        color: #1a316d;
        margin-bottom: 0.4375rem;
        img {
          width: 0.375rem;
          height: 0.1375rem;
        }
      }
      .Banner-title {
        img {
          margin-left: 10px;
          height: 25px;
          width: 135px;
          text-align: center;
        }
        height: 50px;
        line-height: 50px;
        padding-top: 10px;

        width: 100%;
        background-color: #5ebdf1;
      }
      .Banner-content {
        font-size: 0.175rem;
        font-family: SimHei;
        color: #9fd6ff;
        opacity: 0.75;
        margin: 0.2125rem 0;
        margin-left: 10px;
        // height: 39px;
      }

      .Banner-button {
        margin-left: 10px;
        margin-bottom: 0.6rem;
        .Banner-buttonLight {
          width: 114px;
          height: 32px;
          font-size: 0.2rem;

          background: linear-gradient(90deg, #de7150, #ffa65f);
          box-shadow: 0px 4px 29px 3px rgba(226, 79, 51, 0.35);

          border-radius: 5px;
          border: none;
          color: #fff;
          text-align: center;
        }
        .Banner-buttonRight {
          width: 1.425rem;
          height: 0.4rem;
          font-size: 0.2rem;
          background: linear-gradient(90deg, #00b8f9, #69e4fd);
          box-shadow: 0px 4px 29px 3px rgba(10, 79, 142, 0.35);
          border-radius: 5px;
          font-size: 0.2rem;
          border: none;
          color: #fff;
          text-align: center;
        }
      }
      .Banner-buttonLast {
        width: 1.6rem;
        height: 0.45rem;
        background: rgba(150, 161, 189, 0) !important;
        border: 1px solid #ffffff;
        border-radius: 5px;
      }
    }
  }
  .container {
    padding-top: 85px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .views-product-Banner {
    .Banner-header {
      //是因为权重要高上面的 再加一个标签
      padding-top: 68px;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .views-product-Banner {
    .Banner-header {
      height: 514px;
      padding-top: 48px;
      // padding-left: 50px;
      padding-left: 250px;
      .small-img {
        // height: 342px;
        // width: 3.75rem;
        // bottom: 0;
        // z-index: 1;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .views-product-Banner {
    .Banner-header {
      height: 426px;
      padding-top: 48px;
      // padding-left: 50px;
      padding-left: 120px;
      .Banner-left .Banner-content {
        margin: 16px 0 20px;
      }
    }
  }
}
//小于等于768换移动端 移动端可以用rem 将px单位改为rem
@media screen and (max-width: 768px) {
  .views-product-Banner {
    .Banner-header {
      padding-top: 48px;
      // padding-left: 50px;
      padding-left: 20px;
      background-size: cover; //在移动屏幕下我们的图片是不可能显示全的，显示全就会变形，所以只要保证内容再不在盒子外面就行
      height: 350px; //只要保证1920和他的设计稿一样就行，其他的我们随便搞， 但是对方电脑不是不同的宽高吗，那不可能的，你不是图片，你要它看1920，因为设计图是1920的好，屏幕宽，你是按照1920设计的，不然图片会变形的hoaba
      .Banner-left {
        // position: absolute;
        // top: 97px;
        //   top: 0.375rem;

        .Banner-name {
          font-size: 16px;
          margin-bottom: 35px;
          img {
            width: 30px;
            height: 11px;
          }
        }
        .Banner-title {
          img {
            height: 0.6375rem;
            width: 3.4rem;
          }
        }

        .line {
          width: 6.9375rem;
          height: 1px;
          margin-top: 0.225rem;
        }
        .Banner-content {
          font-size: 0.2rem;
          margin: 0.2rem 0 0.6625rem;
        }

        .Banner-button {
          margin-bottom: 0.65rem;
          .Banner-buttonLight {
            font-size: 0.2rem;
            padding: 0.1rem 0.2375rem;
            span {
              letter-spacing: 2px;
            }
          }
          .Banner-buttonRight {
            font-size: 0.2rem;
            padding: 0.1rem 0.2375rem;
          }
        }
        .Banner-buttonLast {
          font-size: 0.2rem;
          padding: 0.1rem 0.2375rem;
        }

        // top: 50%;
      }
    }
  }
}
/deep/.el-button {
  padding: 0;
}

// /deep/.el-divider {
//   background-color: #5ebdf1;
// }
</style>
