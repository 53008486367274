<template>
  <div class="views-product container-product">
    <Banner></Banner>
    <CallOut class="container "></CallOut>
    <System></System>
    <LightSpot></LightSpot>
    <Application></Application>

    <div class="content ">
      <div class="container-item">
        <p class="text">
          <img :src="textImg" alt="" />
        </p>
        <!-- <button class="btn">了解更多</button> -->
        <div class="buttom-item">
          <img :src="bottomkImg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WOW from "wowjs";
import Banner from "@/components/product/Banner.vue";
import CallOut from "@/components/product/CallOut.vue";
import System from "@/components/product/System.vue";
import LightSpot from "@/components/product/LightSpot.vue";
import Application from "@/components/product/Application.vue";

export default {
  name: "Product",
  components: {
    CallOut,
    System,
    LightSpot,
    Application,
    Banner,
  },
  props: {},
  data() {
    return {
      backImg: require("@/assets/images/product/more.png"),
      bottomkImg: require("@/assets/images/product/buttom.png"),
      textImg: require("@/assets/images/product/icon-71.png"),
    };
  },
  create() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.views-product {
  // width: 91.66%;
  margin: auto;
  margin-top: 72px;
  .product-header {
    .product-image {
      width: 100%;
      height: 536px;
    }
  }
  .container {
    padding-top: 1.0625rem;
  }

  .content {
    background: url(../assets/images/product/more.png) no-repeat 100% 100%;
    background-size: cover;
    height: 3.2625rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .container-item {
      bottom: 35%;

      .text {
        width: 100%;
        img {
          width: 5.6375rem;
          height: 1.125rem;
        }
      }
      .buttom-item {
        width: 100%;
        margin-top: -0.625rem;
        img {
          width: 2.35rem;
          height: 1.25rem;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .back-Img {
    height: 0 !important;
    width: 0 !important;
    // height: 6.25rem !important;
    // width: 11.25rem !important;
  }
}
@media screen and (max-width: 680px) {
}
</style>
