<template>
  <div class="components-product-Application ">
    <div class="CallOut-title ">
      <div class="line-content">
        <span class="line-item-left"></span>
        <h2 class="line-call">应用价值</h2>
        <span class="line-item"></span>
      </div>
      <h3 class="line-title">APPLICATION VALUE</h3>
    </div>

    <el-row class="Application ">
      <el-col
        :xs="24"
        :sm="8"
        :md="8"
        class="Application-content"
        v-for="(item, index) in ApplicationList"
        :key="index"
      >
        <div class="Application-foot">
          <div class="Application-Top">
            <img v-lazy="item.imgList" alt="" />
          </div>
          <div class="Application-bottom">
            <p>{{ item.name }}</p>
            <ul v-for="(item, index) in item.duty" :key="index">
              <li>{{ item }}</li>
            </ul>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import WOW from "wowjs";
export default {
  name: "Application",
  data() {
    return {
      ApplicationList: [
        {
          name: "AI提产",
          imgList: require("@/assets/images/product/bannerAI.png"),
          duty: [
            "AI批量外呼省时提效，自动过滤线路盲区",
            "AI实时推送知识点，缩短培训上岗周期",
            "AI智能筛选高意向客户，提高营销精度",
          ],
        },

        {
          name: "体验升级",

          imgList: require("@/assets/images/product/banner-3.png"),
          duty: [
            "轻骚扰过滤机制,主动减少用户骚扰频次",
            "人机协呼声源一致，无缝切换提升体验",
            "漏斗坐席机制，智能分配减少客户等待",
          ],
        },

        {
          name: "企业赋能",

          imgList: require("@/assets/images/product/banner-2.png"),
          duty: [
            "机器24小时持续外呼,降低企业用工成本",
            "AI赋能促进营销转化，提高企业生产效能",
            "多维度话务统计报表，方便企业营销决策",
          ],
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  create() {},
  beforeDestroy() {},
  methods: {},
};
</script>
<style lang="less" scope>
.components-product-Application {
  margin-top: 1.8125rem;
  margin-bottom: 1.225rem;
  .CallOut-title {
    padding-bottom: 39px;
    .line-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 63%;
      margin: 0 auto;
      .line-call {
        font-size: 0.375rem;
        color: #333;
        letter-spacing: 7px;
      }
      .line-item {
        background: url("../../assets/images/product/line-title-right.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
      .line-item-left {
        background: url("../../assets/images/product/line-title-left.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
    }
    .line-title {
      font-size: 0.25rem;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #666;
      letter-spacing: 10px;
    }
  }
  .Application {
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    .Application-content {
      // display: flex;
      // justify-content: space-around;
      padding: 0 0.375rem;
      .Application-foot {
        font-size: 0;
        width: 89%;
        margin: auto;
        // flex-direction: column;

        .Application-Top {
           overflow: hidden;  
          img {
            border-radius: 5px 5px 0px 0px;
            width: 100%;
            cursor: pointer;  
                transition: all 0.6s;  
          }
           img:hover{  
                transform: scale(1.1);  
            }  
        }
        .Application-bottom {
          background: #ffffff;
          box-shadow: 0px 4px 21px 0px rgba(18, 43, 108, 0.18);
          border-radius: 5px;
          padding-top: 0.25rem;
          padding-bottom: 1.15rem;

          p {
            font-size: 0.35rem;
            font-family: SimHei;
            color: #333333;
            line-height: 0.3125rem;
            margin: 0.5rem 0;
          }
          ul > li {
            font-size: 0.2rem;
            font-family: SimHei;
            color: #777777;

            padding: 0.1125rem 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .components-product-Application {
    .Application-content {
      width: 33%;
    }
  }
}
@media screen and (max-width: 680px) {
  .components-product-Application {
    .line-item {
      width: 0.75rem;
    }
    .line-item-left {
      width: 0.75rem;
    }
    .Application-content {
      width: 100%;
      margin-bottom: 20px;
      .Application-foot {
        width: 6.2125rem;
        margin: 0 auto;
      }
    }
  }
}
</style>
