<template>
  <div class="components-product-CallOut ">
    <div class="head-title  animate__animated animate__fadeInUp CallOut-title ">
      <div class="line-content">
        <span class="line-item-left"></span>
        <h2 class="line-call">智能外呼模式</h2>
        <span class="line-item"></span>
      </div>

      <p class="line-title">CALL MODE</p>
    </div>
    <el-row class="ClallOutList ">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        class=" ClallOutList-content "
        v-for="item in ClallOutList"
        :key="item.name"
      >
      <div class="ClallOutList-middle">
      <img v-lazy="item.pic" alt="" />
        <div class="ClallOutList-footer">
          <div class="title">{{ item.title }}</div>
          <div class="tip">{{ item.intro }}</div>
        </div>
      </div>
  
      </el-col>
    </el-row>

    <div class="back-Img"></div>
  </div>
</template>
<script>
import WOW from "wowjs";
export default {
  name: "CallOut",
  data() {
    return {
      active: 0,
      ClallOutList: [
        {
          name: "waihu",
          title: "纯机器人",
          pic: require("@/assets/images/product/waihu.png"),
          intro:
            "由AI独立完成外呼拨号、通话录音、语音交互、营销服务、工单处理全业务流程，适合内容简单的基础类语音营销项目",
        },
        {
          name: "ouhe",
          title: "人机耦合",
          pic: require("@/assets/images/product/ouhe.png"),
          intro:
            "AI拨号接通后自动转人工跟进营销，提前过滤空号、盲线、拒接等无效用户，适合逻辑复杂的语音电销或投诉回访类项目",
        },
        {
          name: "xietong",
          pic: require("@/assets/images/product/xietong.png"),
          title: "人机协同",
          intro:
            "AI负责拨号、知识点搜索、客户情绪监测等重复性工作，支持坐席全程监听并随时无感介入，适合多行业全场景语音营销 ",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {
    onMouseover(index) {
      this.active = index;
    },
  },
};
</script>
<style lang="less" scoped>
.components-product-CallOut {
  .CallOut-title {
    .line-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 63%;
      margin: 0 auto;
      .line-call {
        font-size: 0.5rem;
        font-family: SimHei;
        color: #333333;
        letter-spacing: 7px;
      }
      .line-item {
        background: url("../../assets/images/product/line-title-right.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
      .line-item-left {
        background: url("../../assets/images/product/line-title-left.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
    }
    .line-title {
      font-size: 0.3rem;
      font-family: SimHei;
      color: #666666;
      letter-spacing: 10px;
    }
  }

  .ClallOutList {
    display: flex;
    margin-top: 62px;
    justify-content: space-around;
    /*padding-bottom: 1.9625rem;*/
    margin-bottom: 1.975rem;
    .ClallOutList-content {
      width: 5.675rem;
      height: 560px;
      background: linear-gradient(0deg, #ffffff, #fbfcff);
      box-shadow: 0px 8px 21px 0px rgba(26, 32, 113, 0.1);
      border-radius: 20px;
      box-sizing: border-box;
     
      .ClallOutList-middle{
    
      }
    }
    .ClallOutList-content img {
      margin-top: 50px;

      max-width: 100%;
      max-height: 100%;
    }
    // .ClallOutList-content:nth-child(2) img {
    //   margin-top: 65px;
    // }
    // .ClallOutList-content:nth-child(3) img {
    //   margin-top: 100px;
    // }
    .ClallOutList-footer {
      margin: 39px 71px 88px 62px;
      .title {
        font-size: 0.325rem;
        font-family: SimHei;
        color: #333333;
        text-align: center;
        letter-spacing: 2px;
      }
      .tip {
        letter-spacing: 1.5px;
        margin-top: 39px;
        font-size: 16px;
        font-family: SimHei;
        color: #999999;
        text-align: center;
        line-height: 29px;
      }
    }
  }

  position: relative;
  overflow: hidden;
  .back-Img {
    position: absolute;
    z-index: -1;
    background: url(../../assets/images/product/VI-1.png) no-repeat;
    background-size: contain;

    height: 100%;
    width: 78%;
    left: 45%;
    top: 25%;
  }
  .bottom-Img {
    position: absolute;
    z-index: -1;
    background: url(../../assets/images/product/VI-1.png) no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
    left: 76%;
    top: 62%;
  }
}

@media screen and (max-width: 1500px) {
  .components-product-CallOut {
    .ClallOutList {
      .ClallOutList-content {
        height: 480px;
        img {
          margin-top: 25px;
        }
        .ClallOutList-footer {
          .tip {
            margin-top: 15px;
          }
          margin: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .components-product-CallOut {
    .ClallOutList {
      .ClallOutList-content {
        width: 250px;
        height: 365px;
        img {
          width: 100px;
          height: 100px;
        }
      }
      .ClallOutList-footer {
        margin: 39px 15px 0px 15px;
        .title {
          font-size: 20px;
        }
        .tip {
          /*letter-spacing: 1.5px;*/
          margin-top: 10px;
          font-size: 13px;
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .components-product-CallOut {
    .line-call {
      font-size: 20px !important; /* 40/75 */
    }
    .ClallOutList {
      // display: flex;
      // flex-direction: column;
      // align-items: center;

      .ClallOutList-content {
        width: 225px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .components-product-CallOut {
    .line-content {
      width: 100% !important;
    }
    .line-call {
      font-size: 20px !important; /* 40/75 */
    }

    .ClallOutList {
      display: flex;
      flex-direction: column;
      align-items: center;

      .ClallOutList-content {
        margin-bottom: 50px;
        width: 50% /* 350/75 */;
        height: 7.05rem /* 365/75 */;
        img {
          // width: 100px;
          // height: 100px;
          width: 25%;
          height: 25%;
        }
      }
      .ClallOutList-content:nth-child(3) img {
        margin-top: 50px;
      }
      .ClallOutList-footer {
        margin: 0.52rem /* 39/75 */ 0.667rem /* 50/75 */ 1.173rem /* 88/75 */
          0.667rem /* 50/75 */;
        .title {
          font-size: 20px;
        }
        .tip {
          /*letter-spacing: 1.5px;*/
          margin-top: 0.133rem /* 10/75 */;
          font-size: 13px;
          line-height: 1.5;
        }
      }
    }
  }
}
</style>
