<template>
  <div class="components-product-light">
    <div class="head-title animate__animated animate__fadeInUp CallOut-title">
      <div class="line-content">
        <span class="line-item-left"></span>
        <h2 class="line-call">产品亮点</h2>
        <span class="line-item"></span>
      </div>
      <h3 class="line-title">PRODUCT HIGHLIGHTS</h3>
    </div>

    <!--    <div>-->
    <!--pc缩略图-->

    <div class="thumbnailContainer  hidden-sm-and-down ">
      <div
        :class="[
          'thumbnailItem',
          thumbnailActive === index ? 'thumbnailItemActive' : '',
        ]"
        v-for="(item, index) in thumbnailList"
        :key="index"
        @click="thumbnailItemClick(index)"
      >
        <img v-lazy="thumbnailActive === index ? item.activeImg : item.img" />

        <span>{{ item.name }}</span>
        <span>{{ item.content }}</span>
      </div>
    </div>

    <!--轮播图-->
    <el-carousel
      ref="carousel"
      arrow="never"
      :height="imgHeight + 'px'"
      :autoplay="true"
      indicator-position="none"
      @change="carouselChange"
      style="width: 73%;
    margin: 0 auto;"
      class="hidden-sm-and-down"
    >
      <el-carousel-item
        v-for="(item, index) in LightSpotList"
        :key="index"
        name="index"
      >
        <img ref="bannerImg" :src="item.imgList" @load="initImgHeight" />
        <div class="contentText">
          <img v-lazy="item.imgTriangle" />
          <div class="title">{{ item.title }}</div>
          <p class="p-content">{{ item.content }}</p>
          <p class="p-desc">{{ item.desc }}</p>

        </div>
      </el-carousel-item>
    </el-carousel>

    <div class="bottom-Img"></div>

    <!-- 移动端 -->
    <div class="thumbnailContainer-mobile hidden-md-and-up">
      <div
        :class="[
          'thumbnailItem',
          thumbnailActiveMobile === index ? 'thumbnailItemActive' : '',
        ]"
        v-for="(item, index) in thumbnailList"
        :key="index"
        @click="thumbnailItemClickMobile(index)"
      >
        <img
          v-lazy="thumbnailActiveMobile === index ? item.activeImg : item.img"
          alt=""
        />
        <span>{{ item.name }}</span>
        <span>{{ item.content }}</span>
      </div>
    </div>

    <!--移动轮播图-->
    <el-carousel
      ref="carouselMobile"
      arrow="never"
      :height="imgHeightMobile + 'px'"
      :autoplay="true"
      indicator-position="none"
      @change="carouselChangeMobile"
      style="width: 90%;
    margin: 0 auto;"
      class="hidden-md-and-up"
    >
      <el-carousel-item
        v-for="(item, index) in LightSpotList"
        :key="index"
        name="index"
        class="el-carousel-item-mobile"
      >
        <el-image
          ref="bannerImgMobile"
          :src="item.imgList"
          @load="initImgHeightMobile"
        ></el-image>

        <div class="contentText">
          <div class="title">{{ item.title }}</div>
          <p class="p-content">{{ item.content }}</p>
          <p class="p-desc">{{ item.desc }}</p>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import WOW from "wowjs";
export default {
  name: "LightSpot",
  data() {
    return {
      active: false,
      thumbnailActive: 0,
      imgHeight: 0,
      imgHeightMobile: 0,
      thumbnailActiveMobile: 0,

      thumbnailList: [
        {
          name: "录音保存",
          img: require("@/assets/images/product/icon-1.png"),
          activeImg: require("@/assets/images/product/icon-10.png"),
          content: "多端录音永久保存服务",
        },
        {
          name: "多端登陆",
          img: require("@/assets/images/product/icon-2.png"),
          activeImg: require("@/assets/images/product/icon-35.png"),
          content: "支持内外网和多终端登入",
        },
        {
          name: "分屏模式",
          img: require("@/assets/images/product/icon-3.png"),
          activeImg: require("@/assets/images/product/icon-51.png"),
          content: "将坐席能力与分屏数量自适应",
        },
        {
          name: "可视化管理",
          img: require("@/assets/images/product/icon-4.png"),
          activeImg: require("@/assets/images/product/icon-38.png"),
          content: "全流程数据可视化管理",
        },
        {
          name: "信息安全",
          img: require("@/assets/images/product/icon-7.png"),
          activeImg: require("@/assets/images/product/icon-66.png"),
          content: "多重防护提高数据安全性",
        },
      ],
      LightSpotList: [
        {
          title: "多端录音永久保存服务",
          imgList: require("@/assets/images/product/back-img.png"),
          imgTriangle: require("@/assets/images/product/icon-68.png"),
          content: "坐席和客户分轨录音，减少相互间干扰的同时提高录音可听性。支持PC端和移动端同步存储，且所有音频永久免费保存，企业可实时查询、在线播放和多次下载。",
          desc:"",
        },
        {
          title: "支持内外网和多终端登入",
          imgList: require("@/assets/images/product/back-img.png"),
          imgTriangle: require("@/assets/images/product/icon-68.png"),
          content: "IP登入摆脱局域网限制，降低客户端硬件要求。同时支持PC、手机、PAD多端登入，满足坐席随时随地在线工作需求。",
          desc:"",
        },
        {
          title: "将坐席能力与分屏数量自适应",
          imgList: require("@/assets/images/product/back-img.png"),
          imgTriangle: require("@/assets/images/product/icon-68.png"),
          content: "专利实证的AI分屏能力。支持坐席自主选择单屏或分屏模式。分屏状态下，每个坐席配备相应个数的智能分身助手，坐席通过分屏实时监控多路分身对话记录。同时设置漏斗坐席，减少意向客户转人工服务遇坐席忙线时的等待时长。",
          desc:"相关专利号：ZL201930474164.1；ZL202230394319.2；ZL202111585851.3；ZL202230394320.5；ZL202230394321.X。",
        },
        {
          title: "全流程数据可视化管理",
          imgList: require("@/assets/images/product/back-img.png"),
          imgTriangle: require("@/assets/images/product/icon-68.png"),
          content: "通过ASR语音转文本技术实现通话记录的实时可视化。借助系统报表管理功能完成外呼结果的即时有效分析，并利用可视化大屏实时展示全业务流程数据。",
          desc:"",
        },
        {
          title: "多重防护提高数据安全性",
          imgList: require("@/assets/images/product/back-img.png"),
          imgTriangle: require("@/assets/images/product/icon-68.png"),
          content: "专用接口传输，号码脱敏展示，外呼流程管控和权限分配机制，多重防护绝对保证客户信息安全，有效防止数据资料泄露。",
          desc:"",
        },
      ],
    };
  },

  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
    // this.pcShow();
    // this.pcMpobile();
    this.handlePageChange();
    this.handlePageChangeMobile();
  },
  create() {},
  beforeDestroy() {
    window.removeEventListener("resize", this.initImgHeight);
    window.removeEventListener("resize", this.initImgHeightMobile);
  },

  methods: {
    //初始化获取图片高度及文字容器，设置element轮播的高度
    initImgHeight() {
      // console.log(this.$refs.contentText[0].clientHeight);
      this.imgHeight = this.$refs.bannerImg[0].height;
    },
    initImgHeightMobile() {
      this.imgHeightMobile = this.$refs.bannerImgMobile[0].height;
      var mobileWidth = document.body.offsetWidth;

      if (881 < mobileWidth < 992) {
        this.imgHeightMobile = 600;
      }
      if (681 < mobileWidth < 760) {
        this.imgHeightMobile = 500;
      }
      if (mobileWidth < 680) {
        this.imgHeightMobile = 375;
      }
      if (mobileWidth < 375) {
        this.imgHeightMobile = 300;
      }

      // if (mobileWidth < 680) {
      //   this.imgHeightMobile = 375;
      //   // console.log(mobileWidth, this.imgHeightMobile);
      // }
    },
    //页面发生改变时重新获取图片高度及文字容器高度，并重新设置
    handlePageChange() {
      window.addEventListener("resize", this.initImgHeight);
    },
    handlePageChangeMobile() {
      window.addEventListener("resize", this.initImgHeightMobile);
    },

    //缩略图点击时切换
    thumbnailItemClick(index) {
      this.thumbnailActive = index;
      this.$refs.carousel.setActiveItem(index);
    },

    //轮播图切换时切换缩略图激活状态
    carouselChange(index) {
      this.thumbnailActive = index;
    },

    //移动端缩略图点击时切换
    thumbnailItemClickMobile(index) {
      this.thumbnailActiveMobile = index;
      this.$refs.carouselMobile.setActiveItem(index);
    },

    //移动端轮播图切换时切换缩略图激活状态
    carouselChangeMobile(index) {
      this.thumbnailActiveMobile = index;
    },
  },
};
</script>
<style lang="less" scope>
.components-product-light {
  position: relative;
  overflow: hidden;
  .head-title {
    margin-top: 1.05rem;
    background: url("../../assets/images/product/light.png") no-repeat 100% 100%;
    background-size: cover;
    width: 100%;
    height: 3.0875rem;
    h2 {
      padding-top: 0.5375rem;
      font-size: 0.45rem;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #fff;
    }
    h3 {
      font-size: 0.3rem;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #fff;
    }
  }
  .CallOut-title {
    .line-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 63%;
      margin: 0 auto;
      .line-call {
        font-size: 0.5rem;
        font-family: SimHei;
        color: #fff;
      }
      .line-item {
        background: url("../../assets/images/product/line-right-while.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
      .line-item-left {
        background: url("../../assets/images/product/line-left-while.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
    }
    .line-title {
      font-size: 0.3rem;
      font-family: SimHei;
      color: #eaedf0;
      letter-spacing: 10px;
    }
  }
  // pc
  .thumbnailContainer {
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 32px 0px rgba(18, 43, 108, 0.18);
    border-radius: 5px;
    width: 76%;
    margin: auto;
    height: 133px;
    background: #fff;
    transform: translateY(-0.625rem);
    .thumbnailItem {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // padding: 0.275rem;
      height: 133px;
      /*height: 2.15rem;*/
      position: relative;
      cursor: pointer;
      img {
        max-height: 100%;
        max-width: 100%;
      }
      & :nth-child(2) {
        font-size: 0.25rem;
        font-family: SimHei;
        color: #666666;
        padding: 10px 0 0 0;
        letter-spacing: 2px;
      }
      & :nth-child(3) {
        display: none;
      }
    }
    .thumbnailItem::after {
      content: "";
      /*display: inline-block;*/
      position: absolute;
      /*top: 0.375rem;*/
      /*bottom: 0.375rem;*/
      right: -1px;
      height: 50%;
      margin: 0 auto;
      width: 0.01rem /* 80/80 */;
      background: #d8ddec;
      opacity: 0.38;
    }
    .thumbnailItemActive {
      height: 139px;
      // transform: scale(1, 1.1);
      background: linear-gradient(0deg, #de7150, #ffa65f);
      box-shadow: 0px 4px 29px 3px rgba(226, 79, 51, 0.35);
      border-radius: 5px;
      img {
        max-height: 100%;
        max-width: 100%;
        // background-color: #fff;
      }
      span {
        color: #fff !important;
      }
      & :nth-child(3) {
        font-size: 0.175rem;
        color: #99a6cc;
        display: block;
        opacity: 0.75;
      }
    }
    .thumbnailItem:last-child::after,
    .thumbnailItemActive::after {
      width: 0px /* 80/80 */;
    }
  }
  .el-carousel__item {
    img {
      width: 56%;
    }
    .contentText {
      width: 100%;
      height:330px;
      overflow-y: auto;
      padding: 0 0 0 0.3rem;
      font-size: 0.1rem;

      .title {
        padding: 0.2rem 0 0.3rem;
        font-size: 0.4rem;
        font-family: SimHei;
        color: #333333;
        text-align: left;
        letter-spacing: 3px;
      }
      .p-content {
        font-size: 0.225rem;
        text-align: left;
        font-family: SimHei;
        color: #999999;
        line-height: 0.55rem;
      }
      .p-desc {
        padding-top: 0.2rem;
        font-size: 12px;
        text-align: left;
        font-family: SimHei;
        color: #C0C4CC;
        line-height: 0.225rem;
        transform-origin:left;
        transform:scale(0.5,0.5);
        white-space:nowrap;
      }
      img {
        width: 86px;
        height: 50px;
        transform: translateX(-28px);
      }
    }
    display: flex;
    align-items: center;
  }

  // 移动
  .thumbnailContainer-mobile {
    display: flex;
    box-shadow: 0px 4px 32px 0px rgba(18, 43, 108, 0.18);
    border-radius: 5px;
    height: 133px;
    margin: auto;
    background: #fff;
    transform: translateY(-0.625rem);
    .thumbnailItem {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 133px;
      position: relative;
      cursor: pointer;
      img {
        max-height: 100%;
        max-width: 100%;
      }
      & :nth-child(2) {
        font-size: 0.25rem;
        color: #3387f1;
        padding: 0;
      }
      & :nth-child(3) {
        font-size: 0.175rem;
        color: #99a6cc;
      }
    }
    .thumbnailItem::after {
      content: "";
      /*display: inline-block;*/
      position: absolute;
      /*top: 0.375rem;*/
      /*bottom: 0.375rem;*/
      right: -1px;
      height: 30%;
      margin: 0 auto;
      width: 0.01rem /* 80/80 */;
      background: #d8ddec;
      opacity: 0.38;
    }
    .thumbnailItemActive {
      height: 139px;
      background: linear-gradient(0deg, #ffa65f, #de7150);
      box-shadow: 0px 4px 29px 3px rgba(226, 79, 51, 0.35);
      border-radius: 5px;
      margin-top: -3px;
      img {
        max-height: 100%;
        max-width: 100%;
      }
      span {
        color: #fff !important;
      }
    }
    .thumbnailItem:last-child::after,
    .thumbnailItemActive::after {
      width: 0px /* 80/80 */;
    }
  }
  // .el-carousel_container {
  //   align-items: left;
  // }
  .el-carousel-item-mobile {
    flex-direction: column;

    .contentText {
      width: 100%;
    }
  }

  .bottom-Img {
    position: absolute;
    z-index: -1;
    background: url(../../assets/images/product/VI-1.png) no-repeat;
    background-size: contain;

    height: 100%;
    width: 60%;
    left: 78%;
    top: 45%;
  }
}
@media screen and (max-width: 1200px) {
  .components-product-light {
    .bottom-Img {
      transform: scale(0.8);
    }
  }
}
@media screen and (max-width: 992px) {
  .components-product-light {
    .el-carousel-item-mobile img {
      width: 100%;
    }
  }
}
@media screen and (max-width: 680px) {
  .components-product-light {
    .el-carousel-item-mobile img {
    }
    .contentText {
      padding: 0;
    }
  }
}
@media screen and (max-width: 480px) {
  .components-product-light {
    .bottom-Img {
      display: none;
    }
  }
}

/deep/ .el-carousel .el-carousel--horizontal {
  width: 70%;
  margin: 0 auto;
}
</style>
